<template>
    <div>
        <apexchart
            v-if="!reportContent.bitdefender.graph_data._empty"
            id="bar-bitdefender"
            :height="160"
            type="bar"
            :options="chart_options"
            :series="chart_data"
        />
        <quick-data-table
            id="report-datatable-computers"
            :column-definitions="columns"
            :all-rows="rows"
            :default-sort="{ sort_by: 'name', sort_dir: 'asc' }"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.name }}</h6>
                <p v-if="row.last_user" class="text-gray text-small">{{ row.last_user }}</p>
                <div v-if="!!row.os_version_number">
                    <strong>OS:</strong>&nbsp;{{ row.os_version_number }}
                    <div class="text-small text-gray">
                        <strong>Max supported:</strong>&nbsp;
                        <span v-if="row.os_version_number_max">{{ row.os_version_number_max }}</span>
                        <span v-else>Latest</span>
                    </div>
                </div>
                <div v-else-if="!!row.age">
                    <strong>Age:</strong>&nbsp;
                    <span
                        :class="{ 'text-gray': row.age === 'unknown' }"
                    >
                        {{ row.age }}
                    </span>
                </div>
                <div v-else-if="field === 'backup'">
                    <strong>Backup:</strong>&nbsp;
                    <span v-if="!!row.backup && !row.backup.backup_progression && !!row.backup.last_backup_at">
                        <strong>Finished:</strong> {{ row.backup.last_backup_at | nibnut.date("MM-dd") }}
                    </span>
                    <span v-else-if="!!row.backup && row.backup.backup_progression">
                        {{ row.backup.backup_progression / 100 | nibnut.number("0.00") }}%
                    </span>
                    <span>n/a</span>
                </div>
                <div v-if="!reportContent.bitdefender.graph_data._empty">
                    <strong>Infected Files:</strong>&nbsp;
                    {{ row.viruses | nibnut.number("0,0") }}
                </div>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'os_version_number'">
                    {{ row.os_version_number }}
                    <div class="text-small text-gray">
                        <strong>Max supported:</strong>&nbsp;
                        <span v-if="row.os_version_number_max">{{ row.os_version_number_max }}</span>
                        <span v-else>Latest</span>
                    </div>
                </div>
                <span
                    v-else-if="field === 'age'"
                    :class="{ 'text-gray': row.age === 'unknown' }"
                >
                    {{ row.age }}
                </span>
                <span v-else-if="field === 'backup'">
                    <span v-if="!!row.backup && !row.backup.backup_progression && !!row.backup.last_backup_at">
                        <strong>Finished:</strong> {{ row.backup.last_backup_at | nibnut.date("MM-dd") }}
                    </span>
                    <span v-else-if="!!row.backup && row.backup.backup_progression">
                        {{ row.backup.backup_progression / 100 | nibnut.number("0.00") }}%
                    </span>
                    <span>n/a</span>
                </span>
                <span v-else-if="field === 'viruses'">
                    {{ row.viruses | nibnut.number("0,0") }}
                </span>
                <span v-else>{{ row[field] }}</span>
            </template>
        </quick-data-table>
    </div>
</template>

<script>
import is_report_panel from "./is_report_panel"

export default {
    name: "ComputersPanel",
    mixins: [is_report_panel],
    computed: {
        chart_options () {
            const nb_ticks = Math.min(4, Math.max(...this.reportContent.bitdefender.graph_data.series[0].data))
            return {
                chart: {
                    toolbar: { show: false }
                },
                title: { text: "Infected Files" },
                grid: {
                    xaxis: { lines: { show: false } }
                },
                dataLabels: { enabled: false },
                xaxis: {
                    type: "dateTime",
                    tickAmount: 15,
                    categories: this.reportContent.bitdefender.graph_data.categories
                },
                yaxis: {
                    tickAmount: nb_ticks,
                    labels: {
                        formatter: (value) => {
                            return value.toFixed(0)
                        }
                    }
                }
            }
        },
        chart_data () {
            return this.reportContent.bitdefender.graph_data.series
        },
        columns () {
            const columns = {
                name: { label: "Computer", sort: (!this.reportContent.bitdefender.graph_data._empty) ? null : "asc", type: "alpha" }
            }
            columns.name.label = "Computer"
            columns.name.label += "<ul class=\"text-small horizontal-list\">"
            columns.name.label += Object.keys(this.reportContent.bitdefender.stats).map(label => {
                return `<li>${this.reportContent.bitdefender.stats[label]} ${label}</li>`
            }).join("")
            columns.name.label += "</ul>"
            const rows = this.rows
            if(rows.length) {
                if(rows.find(row => !!row.client_service_id)) {
                    columns.os_version_number = { label: "OS", sort: null, type: "alpha" }
                    columns.last_user = { label: "User", sort: null, type: "alpha" }
                    columns.age = { label: "Age", sort: null, type: "numeric" }
                }
                if(rows.find(row => row.backup !== false)) columns.backup = { label: "Backup", sort: false }
                if(rows.find(row => row.viruses !== false)) columns.viruses = { label: "Infected Files", sort: "desc", type: "numeric" }
            }
            // if(!this.reportContent.bitdefender.graph_data._empty) columns.viruses = { label: "Infected Files", sort: "desc", type: "numeric" }
            return columns
        },
        rows () {
            return this.reportContent.bitdefender.rows
        }
    }
}
</script>
